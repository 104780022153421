// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  max-width: 38rem;
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left:  auto;
  margin-right: auto;
}

footer {
  margin-bottom: 2rem;
  text-align: center;
}
