.archive {
  list-style: none;
  padding: 0;
}

.archive-posts {
  padding-left: 2rem;
}

.archive-nav {
  padding-left: 2rem;
}

.archive-nav p {
  margin-top: 1rem;
}

.archive-header {
  margin-left: -2rem;  
}