// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
//  display: block;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-5;

  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    display: inline-block;
  }
}

#top-level {
    text-align: right;
}

#top-level ul {
    padding: 0;
    margin: 0;
}

#top-level ul li {
    display: inline
}

.left {
    float: left;
//    width: 49%;
//    font-weight: bold;
//    color: $gray-4;
}