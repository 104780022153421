// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  margin-bottom: 2rem;

  li + li {
    margin-top: .25rem;
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: #303030;
}
.page-title,
.post-title {
  margin-top: 0;
}

//a.external-link {
//  &:link,
//  &:visited,
//  &:active {
//    text-decoration: underline;
//  }
//}

.external-link::after {
  content: "\00a0\2197";
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}

//article p {
//  text-align: justify;
//}

.cv {
  text-align: left;
}

// Related posts
.related {
//  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
//  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.related-posts {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;

  h3 {
    margin-top: 0;
  }

  li {
    small {
      font-size: 75%;
      color: #999;
    }
    
      a {
        color: $gray-5;
      }

//    a:hover {
//      color: #268bd2;
//      text-decoration: none;
//
//      small {
//        color: inherit;
//      }
//    }
  }
}

.video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
